<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Previous Announcements</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
        <DataTable :value="schemeList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" :paginator="true" :lazy="true"
            :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template #empty>No Records found.</template>
            <template #loading>Loading Announcements data. Please wait...</template>
            <Column field="Name" header="Announcements Name" headerStyle="width: 25%" bodyStyle="width: 25%">
                <template #body="{ data }">
                    <div>{{ data.schemelabel ? data.schemelabel : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Date" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <div>{{ data.schemedate ? data.schemedate : "N/A" }}</div>
                </template>
            </Column>
            <Column field="content" header="Content" headerStyle="width: 45%" bodyStyle="width: 45%">
                <template #body="{ data }">
                    <div>{{ data.schemecontent ? data.schemecontent : "N/A" }}</div>
                </template>
            </Column>
            <Column field="sendto" header="Sent To" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <div>{{ data.schemesentto ? data.schemesentto : "N/A" }}</div>
                </template>
            </Column>
            <Column field="action" header="Action" headerStyle="width: 10%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <button type="button" :disabled="data.disabledflag == 1" class="btn custom-view-detail-btn"
                        @click="schemeReportModalOpen()">
                        View
                    </button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            schemeList: [
                {
                    id: 0,
                    schemelabel: "New Year Wishes",
                    schemedate: "31/12/2023",
                    schemesentto: "3,39,874",
                    schemecontent: "I urge the people to strive towards newer and greater goals. Let us recess, reflect and get ready to welcome the New Year 2024 with new hope. I wish everyone a joy and peace. May all have a Blessed, Happy and prosperous New Year 2024,",
                    disabledflag: 1,
                },
                {
                    id: 4,
                    schemelabel: "Government Notifications",
                    schemedate: "07/05/2024",
                    schemesentto: "2,04,093",
                    schemecontent: "Notification- 7th May, 2024 is declared as a public holiday, being the Polling Day for the General Election to the House of the People (Lok Sabha), 2024",
                    disabledflag: 1,
                },
            ],
            totalRecords: 2,
            loading: false,
            addschememodalsflag: false,
            schemename: "",
            modalloader: false,
            schemestatusflag: 0,
            disabledflag: 0,
        };
    },
    validations() {
        return {
            schemename: {
                required: helpers.withMessage("Please enter scheme name", required),
            },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        // this.loading = true;
        // this.getcampaigntemplates();
    },

    methods: {
        // getcampaigntemplates(item) {
        //     this.ApiService.getcampaigntemplates(item).then((data) => {
        //         if (data.success === true) {
        //             this.schemeList = data.records;
        //             this.totalRecords = data.totalcount;
        //             this.loading = false;
        //         } else {
        //             this.loading = false;
        //             this.schemeList = null;
        //             this.totalRecords = 0;
        //         }
        //     });
        // },
        changePage(event) {
            this.page_no = event.page;
            // this.getcampaigntemplates({ page_no: this.page_no });
        },
        addschemeModalOpen() {
            this.addschememodalsflag = true;
        },
        addschemeModalClose() {
            this.addschememodalsflag = false;
        },
    },
};
</script>
<style scoped>
.modal-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-submit-btn:focus {
    box-shadow: none;
}
.scheme-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.scheme-badge.status-open {
  background: #c8e6c9;
  color: #256029;
}

.scheme-badge.status-closed {
  background: #ffcdd2;
  color: #c63737;
}
</style>